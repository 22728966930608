import store from "@/store";
import * as moment from 'moment';

/**
 *
 * @type {{progressColor: ((function(*): (string|undefined))|*), dateFormat(*): string, export(*, *): void}}
 */
export const func = {

    /**
     *
     * @param el
     * @returns {string}
     */
    progressColor: (el) => {
        if (el <= 30) {
            return 'red'
        }
        if (el > 30 && el <= 60) {
            return 'yellow'
        }
        if (el > 60 && el <= 90) {
            return 'orange'
        }
        if (el > 90 && el <= 100) {
            return 'success'
        }
    },

    /**
     *
     * @param url
     * @param params
     */
    export(url, params) {
        let token = store.state.user.token
        let userId = store.state.user.id
        let DownloadURL = process.env.VUE_APP_BASE_URL + '/api/' + url + '?token=' + token + '&id=' + userId + '&params=' + JSON.stringify(params);
        window.open(DownloadURL, '_blanc')
    },

    /**
     *
     * @param date
     * @returns {string}
     */
    dateFormat(date) {
        return moment(date).locale('fr').format('DD MMM YYYY');
    },

    /**
     *
     * @returns {string}
     */
    dateNow() {
        return moment().format('DD/MM/YYYY');
    },

    /**
     *
     * @param date
     * @returns {string}
     */
    dateTimeFormat(date) {
        return moment(date).format('DD/MM/YYYY, HH:mm');
    },

    /**
     *
     * @param date
     * @returns {string}
     */
    timeFormat(date) {
        return moment(date).format('HH:mm');
    },

    /**
     *
     * @param hour
     * @returns {string}
     */
    hourFormat(hour) {
        return moment(hour,'HH:mm:ss').format('HH:mm');
    },

    /**
     *
     * @returns {string}
     */
    randomColor() {
        const colors = ["bg-success-subtle success--text", "bg-red-subtle red--text", "bg-orange-subtle orange--text", "bg-primary-subtle primary--text", "bg-blue-subtle blue--text"]
        const random = Math.floor(Math.random() * colors.length)
        return colors[random]
    },

    /**
     *
     * @param fullName
     * @returns {string}
     */
    avatarName(fullName) {
        return fullName.split(' ').map(name => name[0]).join('').toUpperCase()
    },

    /**
     *
     * @param value
     * @returns {string}
     */
    formatPrice(value) {

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'DZD',
            minimumFractionDigits: 2
        }).format(value).replace('DZD', '').trim();
    }
}